import axios from 'axios';
import {
    FINCEN_BOI_ENDPOINT,
    FINCEN_ENDPOINT,
    FINCEN_OFFERS_ENDPOINT,
    FINCEN_PUBLIC_OFFERS_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import FRSO_DTO from "@/dto/request/fincen/frso/FRSO_DTO";
import {AddBOIReportPayload} from "@/dto/fincen/AddBOIReportPayload";
import Page from "@/dto/taxation/Page";
import BOIReportDTO from "@/dto/fincen/BOIReportDTO";
import QueryUtils from "@/utils/QueryUtils";

class FinCENService {

    createOffers() {
        return axios.put(FINCEN_OFFERS_ENDPOINT, null, {headers: authHeader()})
    }

    getOfferByPublicId(id: string) {
        return axios.get<FRSO_DTO>(`${FINCEN_PUBLIC_OFFERS_ENDPOINT}/${id}`);
    }

    updateOfferByPublicId(id: string, payload: FRSO_DTO) {
        return axios.put(`${FINCEN_PUBLIC_OFFERS_ENDPOINT}/${id}`, payload);
    }

    getOfferById(id: number) {
        return axios.get<FRSO_DTO>(`${FINCEN_OFFERS_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updateOfferById(id: number, payload: FRSO_DTO) {
        return axios.put(`${FINCEN_OFFERS_ENDPOINT}/${id}`, payload, {headers: authHeader()});
    }

    addBOIReport(payload: AddBOIReportPayload) {
        return axios.post(FINCEN_BOI_ENDPOINT, payload, {headers: authHeader()});
    }

    getBOIReports(pageNumber: number, pageSize: number, companyId?: number) {
        const queryString = QueryUtils.buildQueryString(
            ["pageNumber", pageNumber],
            ["pageSize", pageSize],
            ["companyId", companyId],
        )
        return axios.get<Page<BOIReportDTO>>(`${FINCEN_BOI_ENDPOINT}${queryString}`, {headers: authHeader()})
    }

}

export default new FinCENService();